<template>
  <div class="header-container">
    <div class="header-left-container">
      <div class="header-logo-container">
        <a :href="host"><img src="@/assets/logo.png" /></a>搜索
      </div>
    </div>
    <div class="header-center-container">
      <div class="center-item-container">
        <div class="center-item" v-for="(d, index) in headItems" :key="index">
            <a :href="d.url">{{d.name}}</a>
        </div>
      </div>
    </div>
    <div class="header-right-container">
        <div class="right-holder"></div>
        <div class="right-reg-container">
            <a href="">注册</a>
        </div>
        <div class="right-login-container">
            <a href="">登录</a>
        </div>
    </div>
  </div>
</template>
<script>
import { HOST_URL } from "common/js/common.js";
export default {
  data() {
    console.log(HOST_URL);
    return {
      host: HOST_URL,
      headItems: [
        {
          name: '首 页',
          url:'/admin/home'
        },
        {
          name: '全景展厅',
          url:''
        },
        {
          name: '版权服务',
          url:''
        },
        {
          name: '关于我们',
          url:''
        }
      ],
    };
  },
};
</script>
<style lang="scss">
.header-container {
  height: 60px;
  line-height: 60px;
  display: flex;
  background: #333333;
  min-width: 1024px;
  .header-left-container {
    width: 300px;
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    .header-logo-container {
      img {
        width: 70px;
        height: 23px;
      }
    }
  }
  .header-center-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .center-item-container {
      display: flex;
      .center-item {
        height: 60px;
        line-height: 60px;
        color: #ffffff;
        font-weight: 500;
        margin-left: 40px;
        font-size: 14px;
        a{
            color:#ffffff;
        }
        a:hover{
            border-bottom: .5px solid #ffffff;
        }
      }
      .center-item:first-child{
          margin-left: 0 !important;
      }
    }
  }
  .header-right-container {
    width: 300px;
    display: flex;
    .right-holder{
        flex: 1;
    }
    .right-reg-container,.right-login-container{
        font-size: 14px;
        font-weight: 500;
        margin-right: 80px;
        a{
            color:#ffffff;
        }
        a:hover{
            border-bottom: .5px solid #ffffff;
        }
    }
  }
}
</style>