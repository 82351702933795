<template>
    <div class="footer-container">
        我是脚
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss">
    
</style>