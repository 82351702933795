<template>
  <div class="custom-container">
    <div class="custom-home-body-container">
      <router-view />
    </div>
    <v-footer></v-footer>
    <div class="custom-fixed-header">
      <v-header></v-header>
    </div>
  </div>
</template>
<script>
import VHeader from "./components/header";
import VFooter from "./components/footer";
export default {
  components: {
    VHeader,
    VFooter,
  },
};
</script>
<style lang="scss">
.custom-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-width: 1024px;
  .custom-fixed-header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
  }
  .custom-home-body-container {
    flex: 1;
  }
}
</style>